import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-page',
  styleUrls: ['./page.component.scss'],
  templateUrl: './page.component.html',
})
export class PageComponent {
  mapsSelector() {
    window.open('https://goo.gl/maps/NEfN77cDTgJ5mKuE6');
  }

  shouldShowVideoSection(): boolean {
    this.cookieValue = this.cookieService.get('P(*&^DKAMDL!)DA');
    return !!this.cookieValue.includes('PALSDO(!KJSASMDASD!#!@#LDASD');
  }

  constructor(private cookieService: CookieService) {
  }

  cookieValue = 'UNKNOWN';
}
