import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public sections = [
    {url: 'section-a', active: false, pageTop: true},
    {url: 'section-b', active: false},
    {url: 'section-c', active: false},
    {url: 'section-d', active: false},
  ];

  
  constructor(
  ) {
    console.log('app component');
  }

}

