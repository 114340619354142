import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import * as $ from 'jquery';
import {Location} from '@angular/common';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {


  constructor(private location: Location) {
  }

  public ngOnInit(): void {
  }

  public changeUrl(url): void {
    this.location.go(url);
  }
}
