import {Component, HostListener, OnInit, PLATFORM_ID, Inject} from '@angular/core';
import {TalksService} from '../talks.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.scss']
})
export class SpeakersComponent implements OnInit {

  value = 'Show more';

  constructor(private talksService: TalksService, @Inject(PLATFORM_ID) private platformId: any) {
    
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId))
      this.onResize(event);
  }

  width;
  showMainSpeakers;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;

    if (this.width < 765) {
      this.showMainSpeakers = 3;
    } else {
      this.showMainSpeakers = 6;
    }
  }

  public speakers = this.talksService.getSpeakersOnMainPage();
}
