import {Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {HttpClient} from '@angular/common/http';
import {VideoDto, VideoHistoryService} from './VideoHistoryService';

import {VideoPlayerComponent} from '../video-player/video-player.component';

@Component({
  selector: 'app-video-player-side-menu',
  templateUrl: './video-player-side-menu.component.html',
  styleUrls: ['./video-player-side-menu.component.css']
})
export class VideoPlayerSideMenuComponent implements OnInit {
  public type: string = 'component';
  public disabled: boolean = false;

  public config: PerfectScrollbarConfigInterface = {};

  // @ts-ignore
  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;
  // @ts-ignore
  @ViewChild(PerfectScrollbarDirective, {static: false}) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('scrollableContent') elementView: ElementRef;

  constructor(private http: HttpClient, private videoService: VideoHistoryService, private videoPlayer: VideoPlayerComponent) {
  }

  selectedVideo: VideoDto;

  onSelect(hero: VideoDto): void {
    this.selectedVideo = hero;
    this.videoPlayer.userSelectTrack = true;
    this.videoPlayer.updateNowWatching(this.selectedVideo.lecturer_name, this.selectedVideo.title, this.selectedVideo.image_url);
  }

  current: number = 0;

  public track: string;

  public videoHistory$ = this.videoService.requestHistory()

  interval: any;

  ngOnInit(): void {
    // this.videoService.requestHistory().then(video => {
    //   this.videoHistory$ = video;
    // });

    // this.interval = setInterval(() => {
    //   this.videoService.requestHistory().toPromise().then(video => {

    //     if (this.hasChanged(this.videoHistory$, video)) {
    //       this.videoHistory$ = video;
    //     }
    //   });
    // }, 300000);
  }

  private hasChanged(oldVideo: VideoDto[], newVideo: VideoDto[]): boolean {
    return oldVideo !== newVideo;
  }
}
