import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {distinctUntilChanged } from 'rxjs/operators';

export interface VideoLiveNowDto {
  lecturer_name: string;
  title: string;
  image_url: string;
  track_id: string;
  track_visible: string;
  stream_url: string;
  now_watch_visible: string;
}

//const API_ENDPOINT = 'http://affinity.cba.pl/server_live.php'
const API_ENDPOINT = 'https://www.akamaiaffinity.com/cms_server/server_live.php';


@Injectable({
  providedIn: 'root'
})
export class VideoLiveNowService {

  constructor(private http: HttpClient) {
  }

  public requestLive() {
    return this.http.get<VideoLiveNowDto[]>(API_ENDPOINT).toPromise();
  }
}
