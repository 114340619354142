$(document).scroll(function () {
  checkOffset();
});

function checkOffset() {

  if ($(window).width() <= 700) {
    if ($('#button2').offset().top + $('#button2').height()
      >= $('#agenda').offset().top + 30)
      $('#button2').removeClass('fixed-bottom');
    if ($(document).scrollTop() + window.innerHeight < $('#agenda').offset().top)
      $("#button2").addClass("fixed-bottom");
  } else
    $('#button2').removeClass('fixed-bottom');
}
