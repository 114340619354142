import { Component } from '@angular/core';

@Component({
    selector: 'code-page',
    templateUrl: `./code-of-conduct.component.html`,
    styleUrls: ['./regulations.component.scss'],

    // styleUrls: ['../regulations/regulations.component.scss'],
})
export class CodeOfConductComponent {
}
