import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  constructor(private router: Router) {
    window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSc36vJI7ZVFt-4AAT4QGt8XbOa17iG2_fe5z7-3fv1h0zY1xQ/viewform?vc=0&c=0&w=1");
  }

  ngOnInit(): void {
  }

}
