import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {routing} from './app.routes';
import {AngularFullpageModule} from '@fullpage/angular-fullpage';
import {PageComponent} from './page/page.component';
import {SpeakersComponent} from './speakers/speakers.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {NavComponent} from './nav/nav.component';
import {SectionComponent} from './section/section.component';
import {NgtUniversalModule} from '@ng-toolkit/universal';
import {AgendaComponent} from './agenda/agenda.component';
import {AboutAffinityComponent} from './about-affinity/about-affinity.component';
import {TermsComponent} from './terms/terms.component';
import {CodeOfConductComponent} from './code-of-conduct/code-of-conduct.component';
import {FooterComponent} from './footer/footer.component';
import {NgxGalleryModule} from 'ngx-gallery';
import {GalleryComponent} from './gallery/gallery.component';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {VideoPlayerSideMenuComponent} from './video-player-side-menu/video-player-side-menu.component';
import {HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import { CookieComponent } from './cookie/cookie.component';
import { FeedbackComponent } from './feedback/feedback.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


console.log('app module');
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    SpeakersComponent,
    PageComponent,
    VideoPlayerComponent,
    SectionComponent,
    NavComponent,
    AgendaComponent,
    TermsComponent,
    CodeOfConductComponent,
    SectionComponent,
    AboutAffinityComponent,
    FooterComponent,
    GalleryComponent,
    VideoPlayerSideMenuComponent,
    CookieComponent,
    FeedbackComponent
  ],
  imports: [
    NgtUniversalModule,
    BrowserModule,
    NgxGalleryModule,
    AngularFontAwesomeModule,
    FontAwesomeModule,
    HttpClientModule,
    routing,
    AngularFullpageModule,
    PerfectScrollbarModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
