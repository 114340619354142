import {Injectable} from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class TalksService {

  private _speakersMainPage: any[] = [
    {
      id: 'aellis',
      name: `Andy Ellis`,
      company: 'Akamai',
      image: `Andy_Ellis.jpg`,
      talk: {
        topic: 'How people make risk choices?',
        bio: `Andy is Akamai’s Chief Security Officer, and "making the Internet suck less" is his mission. Governing cybersecurity, compliance, and safety for Akamai’s planetary-scale cloud platform since 2000, he has also designed and brought to market Akamai’s TLS acceleration network, its DDoS defense offerings, and several of the core technologies behind its security solutions. He has also guided Akamai’s IT transformation from a flat password-based network to a distributed, zero-trust enterprise based on strong authentication.

Andy is a graduate of MIT with a degree in computer science, and has served as an officer in the United States Air Force with the 609th Information Warfare Squadron and the Electronic Systems Center.

Also active in Internet policy and governance circles, Andy has supported past and present Akamai CEOs in roles on the NIAC and NSTAC, as well as serving on the FCC’s Communications Security, Reliability, and Interoperability Council. He is an affiliate of Harvard’s Berkman Klein Center, and a guest lecturer in executive education at MIT and the Harvard Kennedy School. He is a frequent speaker on topics of Internet security, anthropocentric risk management, and security governance; and occasionally blogs at www.csoandy.com. He can be found on Twitter as @csoandy, where he discusses security, wine, American football, and hairstyling.

Andy is also an Advisor to YL Ventures’ YLV3 Fund, Uptycs, Vulcan Cyber, and Orca Security.

Andy has received The Spirit of Disneyland Award, The Wine Spectator’s Award of Excellence (as The Arlington Inn), the US Air Force Commendation Medal, and the CSO Compass Award.`,
        description: `test`
      }
    },
    {
      id: 'jkretchmar',
      name: `James Kretchmar`,
      company: 'Akamai',
      image: `James_Kretchmar.png`,
      talk: {
        topic: 'Spectre, Meltdown and the Ghost in the Machine',
        bio: `James is the Vice President and CTO for Akamai's Media and Carrier Division, driving technical strategy for delivering the highest quality media at scale over the Internet. In this role, he works with customers and partners whose challenges require technology that’s beyond the leading edge of the industry today. This ranges from serving broadcast quality video over the Internet to very large audiences to delivering high performance, high-reliability website experiences, to securing sites and infrastructure from the largest, most sophisticated attacks on the Internet. Mr. Kretchmar also runs innovation programs with Akamai’s telco carrier partners to advance next generation technologies for delivering content on the Internet.

Previously, James served as Chair of Akamai's Architecture Board, responsible for the review and oversight of technical designs for Akamai's globally distributed intelligent platform, as well as providing company-wide technical guidance. Mr. Kretchmar came to Akamai from MIT in 2004 and during his tenure has also served as an Architect for Akamai's Mapping and Network Management systems. He is a published author on Network Administration and speaks several languages.`,
      }
    },
    {
      id: 'lunderhill',
      name: `Larry Underhill`,
      company: 'Akamai',
      image: `Larry_Underhill.jpg`,
      talk: {
        topic: 'Internet Rush Hour - A Behind the Scenes Look',
        bio: `Larry Underhill the VP of Network Planning and Platform Delivery for Europe, Middle East, and Africa. He is a 19 year veteran of Akamai and has held positions in Support & Services, Engineering, Operations, and Networking while here at the company. He describes himself as a technical generalist who is interested in the intersection of the engineering and operations of Internet-scale, distributed computing platforms.`,
      }
    },
    {
      id: 'jherzog',
      name: `Jonathan Herzog`,
      company: 'Akamai',
      image: `Jonathan_Herzog.png`,
      talk: {
        topic: 'EZPath: a new safety review process for Akamai products',
        bio: `Jonathan earned his PhD in cryptography from MIT in 2004. Afterwards, he worked as a think-tank researcher, college professor, independent consultant, start-up founder, and, again, think-tank researcher. He joined Akamai in 2014, where he focuses on development practices for security and safety (among other things).`,
      }
    },
    {
      id: 'sjakubowski',
      name: `Szymon Jakubowski`,
      company: 'Akamai',
      image: `Szymon_Jakubowski.png`,
      talk: {
        topic: 'Opening Ceremony and U want to hackz? N00b Security Labz',
        bio: `Szymon works as a Senior Technical Enablement Architect at Akamai, leading the Enablement Strategy for Cloud Security Products. With over ten years of experience in IT and Security, he is still very passionate about how different technologies work.`,
      }
    },
    {
      id: 'tsperlea',
      name: `Theodor Sperlea`,
      company: 'Philipps-Universität Marburg',
      image: `Theodor_Sperlea.jpg`,
      talk: {
        topic: 'Hacking Ecology - Averting a mass extinction using (citizen) data science',
        bio: `Theodor is a PhD candidate in the Bioinformatics Group of the Faculty of Mathematics and Computer Science at the Philipps-Universität Marburg, Germany. In his main research project, he applies machine learning methods to ecological datasets in order to identify bioindicators for lake ecosystem status prediction. Making use of the experience gathered there, he now also works as a freelancer for data analysis and data visualization (sperlea.eu)`,
      }
    },
    {
      id: 'tstopa',
      name: `Tomasz Stopa`,
      company: 'IBM',
      image: `Tomasz_Stopa.JPG`,
      talk: {
        topic: 'Quantum computers explained',
        bio: `Tomek works in the IBM Software Lab in Kraków, Poland. He obtained his Ph.D. at AGH University of Science and Technology. With a background in theoretical solid-state physics, he moved to software development many years ago. Currently, he works as a Development Manager in Software Asset Management Space. He is an IBM Q Ambassador and IBM Master Inventor, and has authored many IT-related patents and publications.`,
      }
    },
    {
      id: 'ptorre',
      name: `Petar Torre`,
      company: 'Intel',
      image: `Petar_Torre.png`,
      talk: {
        topic: 'Developing Visual Cloud services faster with the right SDKs',
        bio: `Petar is a Principal Engineer at Intel's Communications Service Providers vertical for data center, network and service transformation. He focuses on NFV and Edge to build consumable Telco Cloud platforms using Cloud Native principles. He joined Intel in 1999 and has held various roles, varying from a technical pre-sales to strategic alliance manager.`,
      }
    },
    {
      id: 'ahaertle',
      name: `Adam Haertle`,
      company: 'ZaufanaTrzeciaStrona.pl',
      image: `Adam_Haertle.jpg`,
      talk: {
        topic: 'Becoming a millionaire (and cybercriminal) in 10 easy steps',
        bio: `Adam gave up his job as a CSO of a large Polish telecommunications company to create a security portal and to become a journalist, part-time researcher, lecturer, and trainer. Each day he spends hours researching security-related trends and shedding light upon complicated security matters to people and industry experts, who do not have  the time or patience to read lengthy reports, obscure papers and blog entries written in forgotten languages. His favorite Google query is: "how to quit vi".`,
      }
    },
    {
      id: 'myoung',
      name: `Molly Elise Young`,
      company: 'Akamai',
      image: `Molly_Elise_Young.jpg`,
      talk: {
        topic: 'Compliance and the Art of Storytelling',
        bio: `Molly Elise joined Akamai in 2017 thanks to the Akamai Technical Academy program (ATA). Following her training, she joined the Security Compliance team as a Security Compliance Analyst. Her work primarily focuses on supporting Akamai’s security compliance posture. Her favorite part of the job is sharing her passion towards compliance and telling stories of how Akamai engineering teams make the company awesome. Her twitter handle is: @MollynniumFalcn`,
      }
    },
    {
      id: 'bjakubowski',
      name: `Bartek Jakubowski`,
      company: 'Akamai',
      image: `Bartosz_Jakubowski.jpg`,
      talk: {
        topic: 'U want to hackz? N00b Security Labz',
        bio: `Bartek is a Pre-Sales Engineer at Akamai, professionally focusing on Web Performance and Cloud Security. He’s passionate about Information Security, Ethical Hacking and Cryptography and holds the CISSP, CCSP and C|EH credentials.`,
      }
    },
    {
      id: 'rbanaszkiewicz',
      name: `Rafał Banaszkiewicz`,
      company: 'Google',
      image: `Rafal_Banaszkiewicz.jpg`,
      talk: {
        topic: 'Kubernetes. Kuber-what-is?',
        bio: `Rafał is a Customer Engineer at Google, working with Google Cloud customers from the CEE region. Previously, he held a similar role at Akamai, where he cooperated with media clients located in the MENA region. Rafał is a fan of open source products, Python and Big Data technologies. In ancient past he worked in the telecommunications field.`,
      }
    },
    {
      id: 'rbieda',
      name: `Roman Bieda`,
      company: 'MARUTA WACHTA',
      image: `Roman_Bieda.jpg`,
      talk: {
        topic: 'Artificial Intelligence (AI) in the context of emerging legal issues',
        bio: `Roman is an attorney-at-law and patent attorney, practicing with Maruta Wachta Law Firm registered partnership. He has 15 years of expertise in the new technologies law in its broadest sense. He deals with contract negotiations in the IT sector and advises on data protection and electronic commerce.
Roman sat on a panel of experts for the Minister for Digitalisation, where he advised on the amendment of national law in light of  GDPR. He has also offered his expertise in the legal aspects of Artificial Intelligence when an AI strategy for Poland was being drafted. He is also a member of the Digital Chair of Ethics and Law.
Roman combines his professional duties with lecturing. He supervises academic research work and lectures at the postgraduate studies of The Law of New Technologies and Business, AI Technology Law, AI Application Law, as well as intellectual property law and new technologies law at the Warsaw School of Economics, Kozminsky University and Katowice School of Economics. 
Previously he lectured at MBA programmes (the West Pomeranian Business School). He has authored numerous papers and books related to IT law and data protection.`,
      }
    },
    {
      id: 'gwiktorzak',
      name: `Gabriela Wiktorzak`,
      company: 'Linklaters Poland Sp. z o.o',
      image: `Gabriela_Wiktorzak.JPG`,
      talk: {
        topic: 'Artificial Intelligence (AI) in the context of emerging legal issues',
        bio: `Gabriela is a UK qualified solicitor and member of the Law Society. Currently, she is working as a business lawyer for Linklaters Poland Sp. z o.o, a software company and a leading digital innovation partner for corporate customers in the UK and Germany. She is a member of a nationwide community of experts centered around the subject of Artificial Intelligence. She passionately participates in the global discussion on the AI, follows the legislative and ethical challenges faced by modern technology and lawmakers. For the past 13 years, she has been responsible for solving legal problems in international commercial and civic organizations. She is driven by her personal belief that the pillars of society are both a spirit of law and a spirit of freedom. Gabriela is a Master of Polish Law (UW/EWSPiA) and has graduated from many prestigious universities, including the London Brunel University, BPP Law School and Kozminski Academy (Postgraduate Studies - Modern Technology Law). She is currently working on her PhD thesis on predictive policing and predictive justice.`,
      }
    },
    {
      id: 'pstachowicz',
      name: `Piotr Stachowicz`,
      company: 'Edrone',
      image: `Piotr_Stachowicz.jpg`,
      talk: {
        topic: 'On-Call burnout is a real thing',
        bio: `Piotr leads engineering teams that build and run highly available distributed systems. He focuses on the human aspect of designing and operating reliable software.`,
      }
    },
    {
      id: 'mkubicki',
      name: `Maciej Kubicki`,
      company: 'Arch IT',
      image: `Maciej_Kubicki.jpg`,
      talk: {
        topic: 'Why imperfection is better?',
        bio: `Maciej is an Enterprise Architect with extensive professional experience gained in various financial institutions. He has a working knowledge of Agile IT Teams task organization and distribution. He is also a skillful designer of comprehensive IT architectures for different businesses.`,
      }
    },
    {
      id: 'csalperwyck',
      name: `Christophe Salperwyck`,
      company: 'Akamai',
      image: `Christophe_Salperwyck.jpg`,
      talk: {
        topic: 'Data Drift: Machine Learning and Unlearning',
        bio: `Christophe is a Lead Data Scientist at Akamai. He specialized in machine learning in data streaming during his PhD at Orange. He is also interested in designing different kinds of scaling algorithms such as CourboSpark--an adaptation of the Spark decision tree for time series for EDF or FlinkDTW, which finds patterns in time series.`,
      }
    },
    {
      id: 'ljanowski',
      name: `Lucjan Janowski`,
      company: 'AGH',
      image: `Lucjan_Janowski.JPG`,
      talk: {
        topic: 'Asking About Video Quality',
        bio: `Lucjan is an assistant professor at the Department of Telecommunications (AGH University of Science and Technology). Throughout the years, he has worked on post-doc positions in various research centers, exploring topics such as malicious traffic and anomaly detection algorithms at the Laboratory for Analysis and Architecture of Systems of CNRS in France, QoE for health applications at the University of Geneva, or QoE on the client’s side in the Telecommunications Research Center Vienna (FTW).
Lucjan’s main interests are statistics and probabilistic modelling of subjects and subjective rates used in QoE evaluation.`,
      }
    },
    {
      id: 'tnowicki',
      name: `Tomasz Nowicki`,
      company: 'Akamai',
      image: `Tomasz_Nowicki.jpg`,
      talk: {
        topic: 'The haunting of the smart house',
        bio: `Tomasz is an IT Specialist and Technical Educator with 15 years of experience in the field. Throughout the years, he has held various roles, such as a Web Application Developer, Technical Project Manager, or Technical Enablement Architect. 
He is passionate about how information technologies impact our daily life and how they can be used to streamline boring tasks, so that we have more time for the interesting ones. He’s been with Akamai for 5 years.`,
      }
    },
    {
      id: 'dczarnota',
      name: `Dominik 'disconnect3d' Czarnota`,
      company: 'Trail of Bits',
      image: `Dominik_disconnect3d_Czarnota.jpg`,
      talk: {
        topic: 'Semantic safety won\'t save you',
        bio: `Dominik is a Security Engineer at Trail of Bits working on code audits, as well as research and development. He also maintains Pwndbg, an open-source plugin for GDB useful for reverse engineering and exploit development. 
Dominik is the captain of justCatTheFish CTF team. He enjoys looking under the hood to understand how things work.`,
      }
    },
    {
      id: 'jbouwsma',
      name: `Joris Bouwsma`,
      company: 'Akamai',
      image: `Joris_Bouwsma.jpg`,
      talk: {
        topic: 'Data Consistency in Complex Systems',
        bio: `Joris is a passionate product leader responsible for log delivery and reporting at Akamai. He’s on a mission to empower users with elegant and powerful data solutions. 
Joris enjoys bringing together perspectives from different disciplines around the world and fusing them into a coherent product. He has a diverse background, spanning sales, customer service and architecture.`,
      }
    },
    {
      id: 'skwapiszewski',
      name: `Szymon Kwapiszewski`,
      company: 'Akamai',
      image: `Szymon_Kwapiszewski.jpg`,
      talk: {
        topic: 'My Smart Home on Akamai Platform',
        bio: `Szymon Kwapiszewski is a Senior Engineering Manager leading the Web Performance Engineering organization at the Akamai Krakow. He is also a member of the Site Steering Team orchestrating all the innovation-related initiatives at the Krakow office. 
Szymon joined Akamai in 2015. Since then, he has successfully launched several teams working on new Web Performance and IoT products.
Prior to Akamai, he spent 10 years at IBM working as a technical leader, people manager, and customer enablement expert. Szymon is a graduate of AGH University of Science and Technology in Kraków.`,
      }
    },
    {
      id: 'mtorres',
      name: `Melissa Chavez Torres`,
      company: 'Akamai',
      image: `Melissa_Chavez_Torres.jpeg`,
      talk: {
        topic: 'Edge computing: One too many questions',
        bio: `Melissa joined Akamai Technologies in 2012 as a Customer Solution Engineer. She used her industry expertise to diagnose the business and technical needs of Akamai EMEA customers and prescribe innovative solutions to meet those needs. 
Currently Melissa is focused on the Akamai Carrier Innovation Program, bringing strategic European carrier partners together with Akamai technologists and product development teams in order to advance next generation technologies in content delivery.`,
      }
    },
    {
      id: 'mwendland',
      name: `Milena Aragon Wendland`,
      company: 'Akamai',
      image: `Milena_Aragon_Wendland.jpg`,
      talk: {
        topic: 'Ethical Machine Learning',
        bio: `Milena leads a team of technical consultants that provide Akamai customers with actionable recommendations on how to improve the performance and security of their web assets.
She's an enthusiast of learning from data, or better still - letting machines learn from it.`,
      }
    },
  ];


  public getSpeakersOnMainPage(): any[] {
    return this._speakersMainPage;
  }
}
