let isButtonOneVisible;

$(document).ready(function () {
  $('#button2').hide();
  isButtonOneVisible = true;

  $("#button1").click(function () {
    $('#button1').hide();
    $('#button2').show();
    isButtonOneVisible = false;

    if ($(window).width() <= 700) {
      $("#button2").addClass("fixed-bottom");
      $("#button2").addClass("moveLeft");
    }
  });

  $("#button2").click(function () {
    if ($(window).width() >= 767) {
      $('html, body').animate({
        scrollTop: $("#speakers").offset().top - $(".navbar-fixed-top").height()
      }, 250);
    }

    if ($(window).width() < 767) {
      $('html, body').animate({
        scrollTop: $("#speakers").offset().top
      }, 250);
    }

    $('#button2').hide();
    $('#button1').show();
    isButtonOneVisible = true;
  });

  

  $('.description').each((index) => {
    let curr = $('.description').eq(index);
    console.log(curr.closest('ul').offset());
    let leftOffset = curr.closest('ul').offset().left;
    
    curr.offset({left: leftOffset});
  });


  $('.description').toggle();

});

$(document).ready(function () {
  scrollTo('sjakubowski');
  scrollTo('tnowicki');
  scrollTo('myoung');
  scrollTo('tstopa');
  scrollTo('ljanowski');
  scrollTo('csalperwyck');
  scrollTo('mkubicki');
  scrollTo('pstachowicz');
  scrollTo('gwiktorzak');
  scrollTo('rbieda');
  scrollTo('rbanaszkiewicz');
  scrollTo('bjakubowski');
  scrollTo('ahaertle');
  scrollTo('ptorre');
  scrollTo('tsperlea');
  scrollTo('jherzog');
  scrollTo('jkretchmar');
  scrollTo('aellis');
  scrollTo('skwapiszewski');
  scrollTo('dczarnota');
  scrollTo('mtorres');
  scrollTo('jbouwsma');
  scrollTo('lunderhill');
  scrollTo('mwendland');
});

function scrollTo(speaker) {
  $(document).on('click', '#' + speaker + ' .speaker', function (event) {
    event.preventDefault();
    shouldOpen(speaker);
    $('html, body').animate({
      scrollTop: $('[id$="' + speaker + '"]').offset().top - $(".navbar-fixed-top").height() - 10
    }, 500);
  });

  $('#' + speaker + ' .title').click(e => {
    e.preventDefault();
    toggleDescription(speaker);
    
  });

  $('#' + speaker + ' .titleBio').click(e => {
    if ($('#' + speaker + ' .description').css('display') != 'block') {
      toggleDescription(speaker);
    }
    $('html, body').animate({
      scrollTop: $('#' + speaker + ' .description').offset().top
    });
  });
}

function toggleDescription(speaker) {
  if ($('#' + speaker + ' .description').hasClass('unfolded') )
    return;
  $('.description.unfolded').toggle(200);
  $('.description.unfolded').removeClass('unfolded');
  $('#' + speaker + ' .description').toggle(200);
  $('#' + speaker + ' .description').addClass('unfolded');
}

function shouldOpen(id) {
  if ($(window).width() < 767) {
    if (!id.includes('ellis') && !id.includes('kretchmar') && !id.includes('lunderhill')) {
      if (isButtonOneVisible) {
        $('#button1').click();
      }
    }
  }
  if ($(window).width() >= 767) {
    if (!id.includes('ellis') && !id.includes('kretchmar') && !id.includes('herzog') && !id.includes('sjakubowski') && !id.includes('sperlea') && !id.includes('lunderhill')) {
      if (isButtonOneVisible) {
        $('#button1').click();
      }
    }
  }
}

