import { Component, OnInit } from '@angular/core';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about-affinity',
  templateUrl: './about-affinity.component.html',
  styleUrls: ['./about-affinity.component.css']
})
export class AboutAffinityComponent implements OnInit {

  ngOnInit(): void {
  }
}
