import {Component, Input, OnInit} from '@angular/core';
import {VideoLiveNowDto, VideoLiveNowService} from './VideoLiveNowService';
import {VideoPlayerSideMenuComponent} from '../video-player-side-menu/video-player-side-menu.component';

declare var jQuery: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  constructor(private liveNow: VideoLiveNowService) {
  }

  name: string;
  title: string;
  image: string;
  interval: any;

  selectedVideo: VideoLiveNowDto;

  onSelect(hero: VideoLiveNowDto): void {
    this.selectedVideo = hero;
    this.userSelectTrack = true;
    console.log(this.userSelectTrack);
    console.log(this.showNowWatching);

    this.updateNowWatching(hero.lecturer_name, hero.title, hero.image_url);
  }

  live;
  public vod;

  private liveWatchingNow(index: number) {
    this.live = index;
    this.vod = 100;
  }

  public vodWatchingNow(index: number) {
    this.vod = index;
    this.live = 100;
  }


  public updateNowWatching(name: string, title: string, image: string) {
    this.name = name;
    this.title = title;
    this.image = image;
  }

  public switchTrack(track_url: string, title: string) {
    (function ($) {
      $(document).ready(function () {
        // @ts-ignore
        changeHIstoryTrack(title, track_url);
      });
    })(jQuery);
  }

  public videoLive$: VideoLiveNowDto[];
  public showNowWatching;
  public userSelectTrack = false;

  ngOnInit(): void {
    this.liveNow.requestLive().then(liveDto => {
      this.videoLive$ = liveDto;
      this.showNowWatching = liveDto[0].now_watch_visible;
      this.defaultLiveData();
    });

    this.interval = setInterval(() => {
      this.liveNow.requestLive().then(live => {
        if (this.hasLiveDtosChanged(this.videoLive$, live)) {
          this.showNowWatching = live[0].now_watch_visible;
          this.videoLive$ = live;
        }
        this.defaultLiveData();
      });
    }, 30000);
  }

  defaultLiveData() {
    if (this.videoLive$ == null) {
      this.videoLive$ = [
        {
          track_visible: '1',
          lecturer_name: ``,
          image_url: ``,
          track_id: `0`,
          stream_url: `https://affinity2019.akamaized.net/hls/live/733811/affinity2k19_track01/master.m3u8`,
          title: ``,
          now_watch_visible: `1`,
        },
        {
          track_visible: '1',
          lecturer_name: ``,
          image_url: ``,
          track_id: `1`,
          stream_url: `https://affinity2019.akamaized.net/hls/live/733810/affinity2k19_track02/master.m3u8`,
          title: ``,
          now_watch_visible: `1`,
        },
        {
          track_visible: '1',
          lecturer_name: ``,
          image_url: ``,
          track_id: `2`,
          stream_url: `https://affinity2019.akamaized.net/hls/live/733809/affinity2k19_track03/master.m3u8`,
          title: ``,
          now_watch_visible: `1`,
        },
      ];
    }
  }

  private hasLiveDtosChanged(old: VideoLiveNowDto[], newLive: VideoLiveNowDto[]) {
    return old !== newLive;
  }

}

