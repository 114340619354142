import { Component,  AfterViewInit, ViewChild, ElementRef, AfterViewChecked, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// import * as jQuery from 'jquery';
declare const jQuery: any;

@Component({
  selector: 'app-agenda',
  templateUrl: `./agenda.component.html`,
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements AfterViewInit, OnInit, AfterViewChecked {

  private fragment: string;
  constructor(private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId) {}

  public ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  public ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView();
        window.scrollBy(0, -60);
      }
    } catch (e) { }
  }

  public ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    jQuery('.tags span').each(index => {
      const actual = jQuery('.tags span')[index];
      actual.onclick = () => {
        jQuery('div.highlighted').removeClass('highlighted');
        console.log('div.' + actual.textContent.substring(1).toLowerCase());
        jQuery('div.' + actual.textContent.substring(1) + ''.toLowerCase()).addClass('highlighted');
      };
      // console.log(actual.textContent.substring(1));
    });
  }
}
