import { Component, OnInit } from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions} from 'ngx-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = new Array();
  photosNumber2018 = 190;

  ngOnInit(): void {

    this.galleryOptions = [
      {
        width: '1024px',
        height: '150px',
        image: false,
        lazyLoading: true,
        previewCloseOnClick: true,
        imageSwipe: true,
        previewKeyboardNavigation: true,
        previewSwipe: true,
        previewCloseOnEsc: true,
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Fade,
        imageSize: NgxGalleryImageSize.Contain
      },
      // max-width 1173
      {
        breakpoint: 1173,
        width: '100%',
        height: '150px',
        thumbnailsColumns: 4,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 900,
        thumbnailsColumns: 3,
        preview: true
      },
      {
        breakpoint: 700,
        thumbnailsColumns: 2,
        preview: true
      }

    ];

    for (let i = this.photosNumber2018+1; i < 472; i++) {

      this.galleryImages.push({
        small: 'https://images.akamaiaffinity.com/Images/2018/Picture' + i + '.jpg?imwidth=320',
        medium: 'https://images.akamaiaffinity.com/Images/2018/Picture' + i + '.jpg?imwidth=1024',
        big: 'https://images.akamaiaffinity.com/Images/2018/Picture' + i + '.jpg?imwidth=2048'
      });
    }
  }
}
