let amp;

function changeHIstoryTrack(title, src) {
  amp.setMedia(
    {
      title: title,
      poster: '../assets/poster.jpg',
      duration: '',
      source: [{
        src: src,
        type: "application/x-mpegURL"
      }]
    }
  );
}

function loadHandler(event) {
  let config = {
    autoplay: false,
    volumepanel: {
      direction: "vertical",
      fullscreen: {
        mode: "external"
      }
    },
    media: {
      title: "Opening Ceremony",
      poster: '../assets/poster.jpg',
      duration: '',
      source: [{
        src: "https://affinity2019.akamaized.net/hls/live/733811/affinity2k19_track01/master-archive.m3u8?startTime=1573113890&endTime=1573114653",
        type: "application/x-mpegURL"
      }]
    },
    plugins: {
      mediaanalytics: {
        debug: false,
        config: "https://ma1295-r.analytics.edgekey.net/config/beacon-25807.xml",
        iplookup: true
      },
    }
  };
  amp = akamai.amp.AMP.create("akamai-media-player", config);
}
