import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit {

  constructor(private cookieService: CookieService, private router: Router) {
  }

  cookieValue = 'UNKNOWN';

  ngOnInit(): void {
    this.cookieService.set('P(*&^DKAMDL!)DA', 'PALSDO(!KJSASMDASD!#!@#LDASD');
    this.cookieValue = this.cookieService.get('P(*&^DKAMDL!)DA');
    this.router.navigate(['/']);
  }

}
