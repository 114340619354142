import { Component, Input } from '@angular/core';
import {SectionModel} from '../../../projects/aotearoan/angular-fullpage/src/lib/fullpage/section.model';


@Component({
  selector: 'app-section',
  styleUrls: ['./section.component.scss'],
  templateUrl: './section.component.html',
})
export class SectionComponent {
  @Input() public section: SectionModel;
}
