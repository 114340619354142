$(document).on('click', '.scroll-speakers', function (event) {
  $('html, body').animate({
    scrollTop: $("#speakers").offset().top - $(".navbar-fixed-top").height(),
  }, 500);
});

$(document).on('click', '.scroll-about', function (event) {
  $('html, body').animate({
    scrollTop: $("#aboutAffinity").offset().top - $(".navbar-fixed-top").height()
  }, 500);
});

$(document).on('click', '.scroll-home', function (event) {
  $('html, body').animate({
    scrollTop: $("#homeScroll").offset().top - $(".navbar-fixed-top").height(),
  }, 500);
});

$(document).on('click', '.scroll-agenda', function (event) {
  $('html, body').animate({
    scrollTop: $("#agenda").offset().top - $(".navbar-fixed-top").height()
  }, 500);
});

$(document).on('click', '.scroll-ctf', function (event) {
  $('html, body').animate({
    scrollTop: $("#ctf").offset().top - $(".navbar-fixed-top").height()
  }, 500);
});

$(document).on('click', '.scroll-live', function (event) {
  $('html, body').animate({
    scrollTop: $("#stream").offset().top - $(".navbar-fixed-top").height()
  }, 500);
});

