import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface VideoDto {
  content_id: string;
  image_url: string;
  lecturer_name: string;
  stream_url: string;
  title: string;
}

//const API_ENDPOINT = 'http://affinity.cba.pl/server_vod.php';
const API_ENDPOINT = 'https://www.akamaiaffinity.com/cms_server/server_vod.php';

@Injectable({
  providedIn: 'root'
})
export class VideoHistoryService {

  constructor(private http: HttpClient) { }

  public requestHistory() {
    return this.http.get<VideoDto[]>(API_ENDPOINT);
  }
}
