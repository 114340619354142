import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './page/page.component';
import {TermsComponent} from './terms/terms.component';
import {CodeOfConductComponent} from './code-of-conduct/code-of-conduct.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {CookieComponent} from './cookie/cookie.component';
import {FeedbackComponent} from './feedback/feedback.component';

export const routes: Routes = [
  { path: '', component: PageComponent },
  { path: 'agenda', component: PageComponent },
  { path: 'about', component: PageComponent },
  { path: 'speakers', component: PageComponent },
  { path: 'regulations', component: TermsComponent },
  { path: 'code-of-conduct', component: CodeOfConductComponent },
  { path: 'video', component: VideoPlayerComponent },
  { path: 'showVideoSection', component: CookieComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: '**', redirectTo: '/' }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
